@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
  margin: 0px;
  padding: 0px;
}

* {
  font-family: 'Roboto', sans-serif;
  color: #214468;
}

h3 {
  background: -webkit-linear-gradient(left, #00E1FD, #FC007A);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media only screen and (min-width: 1100px) {
  .containerBox {
    width: 30vw;
    height: 70vh;
    margin-bottom: 8rem;
  }
  
  .logo {
    padding-top: 3rem;
    padding-bottom: 2rem;
  }
  
  .textField {
    width: 90%;
  }
}

@media only screen and (max-width: 600px) {
  h1, h2, h3 {
    font-size: 2rem !important;
  }
  h4, h5, h6 {
    font-size: 1.2rem !important;
  }
  .containerBox {
    width: 80vw;
    height: 70vh;
    margin-bottom: 6rem;
  }
  .logo {
    padding-top: 2rem;
  }
  .textField {
    width: 100%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1100px) {
  .containerBox {
    width: 60vw;
    height: 70vh;
    margin-bottom: 8rem;
  }
  .logo {
    padding-top: 3.5rem;
  }
  .textField {
    width: 80%;
  }
}